@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: poppins, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  background-color: #bae2ac80;
}

code {
  font-family:
    source-code-pro,
    Menlo,
    Monaco,
    Consolas,
    "Courier New",
    monospace;
}

@import url("https://fonts.googleapis.com/css2?family=Nunito&display=swap");

* {
  box-sizing: border-box;
}

html {
  font-family: "Nunito", sans-serif;
  font-size: 22px;
  color: #191616;
}

.App {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 1rem 0.5rem;
  text-align: center;
}

.my-height {
  height: 100vh;
  background-color: #95bf02;
}

.log-section {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 420px;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1.5rem;
  background-color: #49a92780;
  border-radius: 0.5rem;
}

form {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  flex-grow: 1;
  padding-bottom: 1rem;
}

.instructions {
  font-size: 0.75rem;
  border-radius: 0.5rem;
  background: #2c2c2c;
  color: #fff;
  padding: 0.15rem;
  position: relative;
  bottom: -10px;
}

.instructions > svg {
  margin-right: 0.25rem;
}

.offscreen {
  position: absolute;
  left: -9999px;
}

.hide {
  display: none;
}

.valid {
  color: limegreen;
  margin-left: 0.25rem;
}

.invalid {
  color: red;
  margin-left: 0.25rem;
}

.errmsg {
  background-color: lightpink;
  color: rgb(203, 49, 49);
  font-weight: bold;
  padding: 0.25rem;
  margin-bottom: 0.5rem;
  border-radius: 0.5rem;
}

.line {
  display: inline-block;
}

.button {
  background-color: #51b318;
  border: none;
  border-radius: 0.5rem;
  padding: 0.25rem;
  font-size: 1rem;
  cursor: pointer;
  color: #000;
}

.password-input {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.password-toggle {
  background-color: #51b318;
  color: #fff;
  border: none;
  border-radius: 0.5rem;
  padding: 0.25rem;
  font-size: 1rem;
  cursor: pointer;
}

.welcome {
  background-image: url('../images/bg-merc.svg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  margin: 0;
}
